@import "../global.scss";
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');



.conteneedorCalendario1{
    //display: flex !important;
    //flex-direction: column !important;

    @include tabletPortrait {
        display: flex !important;
        flex-direction: row !important; 
    }
    
}

.esconder{
    @include extraMui{
        visibility: hidden;
        display: none !important;
    }
    @include tabletPortrait{
        visibility: hidden !important;
    }

    @include mobile{
        visibility: hidden !important;
    }
}

.botonHoras{
    background-color: #EE6E2D !important;
    border:white;
    color: white;

    margin-left:15px !important;
}

.botonHorasReservado{
    margin-left:15px !important;  
}

.bookingTimesNull{
    visibility: hidden;
}

.botonBorrarRe{
    all: unset;
}

.tablaReserva{
    margin-top: 35px;
    margin-left: 100px;
}

.botonesHorarios{
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 400px; // Si pones 300 los botones aparecen en 2 filas nada mas
    height: 55px;
    margin-top:10px;

    @include tabletPortrait{
        max-width: 200px;
    }
    
}

.botonesHorariosCard{
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 400px; // Si pones 300 los botones aparecen en 2 filas nada mas
    
    margin-top:10px;

    @include tabletPortrait{
        max-width: 200px;
    }
}

.textoCard{
    padding-right: 30px;
}

.esconder2{
    visibility: hidden ;
    display:none;
}


.react-calendar{
    border-color:rgba(0, 0, 0, 0.08) !important;
    padding-bottom: 40px;

    width: 680px !important;

    @include desktop{
        width: 500px !important;
    }
    @include tabletLandSacpe{
        width: 450px !important;
    }

    @include tabletPortrait{
        width: 85vw !important;
        margin-left: -5vw;
    }

    @include mobile{
        width: 85vw !important;
    }
}

.react-calendar__navigation{
    margin-top: 20px;
}


.react-calendar__navigation__label{
    color:#ee6e2d;
    font-size:30px;
    font-weight:700;
    line-height:42.8571px;
    text-align:center;
    
    
}

.react-calendar__navigation__arrow, .react-calendar__navigation__prev-button{
    background-color: #FFF !important;
    
}



.react-calendar__tile--now {
    background: #FFF !important;
}

.react-calendar__tile--now abbr{
    color:#ff6358 !important;
}

.react-calendar__tile--active {
    background: #EE6E2D !important;
    //background: #ff6358 !important;
    border-radius: 8px !important;
}

.react-calendar__tile--now{
    abbr{
        color: #EE6E2D !important;
        font-weight: bold !important;
    }
}


.react-calendar__tile--active abbr{
    color:#FFF !important;
}

.react-calendar__tile:disabled {
    background-color: #ffffff !important;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.react-calendar__tile {
    font-size:25px;
}

.react-calendar__tile:disabled abbr{
    color:#cccccc !important;
}

.react-calendar__month-view__days__day--neighboringMonth.button.abbr:not(:active){
    color: #5C6D7E !important;
}



abbr[title="lunes"], abbr[title="martes"], abbr[title="miércoles"], abbr[title="jueves"], abbr[title="viernes"], abbr[title="sábado"], abbr[title="domingo"] {
    all:unset;
    color:#666666;
    font-size:25px;
    line-height:50px;
    text-align:center;
    text-transform:uppercase;
    text-decoration: none;
    cursor: default;
    font-weight: normal;
    opacity: 0.9;
}




