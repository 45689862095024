@import "../global.scss";

.grid-container{
  display: flex;
  justify-content: center;
  margin-top: 4vh;
  margin-bottom: 2vh;
  width: 100%;
}

.horarios-grid {
  border-collapse: collapse;
  width: 90%; /* Cambiado a 100% para llenar el contenedor en todos los dispositivos */
}

.horarios-grid th,
.horarios-grid td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: center;
  white-space: nowrap;
}

@media (min-width: 768px) { /* Para tabletas en modo retrato y dispositivos más grandes */
  .horarios-grid th,
  .horarios-grid td {
    font-size: 20px;
  }
}

@media (max-width: 767px) { /* Para dispositivos móviles */
  .horarios-grid th,
  .horarios-grid td {
    font-size: 1.84vw; /* Cambiado a vw para un escalado más fluido */
  }
}

.hora-cell {
  cursor: pointer;
}

.selected {
  background-color: orange;
}

.disabled {
  background-color: lightgray;
}

.horarios-select-container {
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) { /* Para dispositivos móviles */
  .MuiTableCell-body {
    font-size: 2vw !important; /* Cambiado a vw para un escalado más fluido */
  }
}


.month-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin-bottom: -20px;
}

.arrow {
  all: unset;
  background: none;
  border: none;
  outline: none; 
  color: #ee6e2d;
  cursor: pointer;
  font-size: 24px;
  padding: 0; 
  -webkit-appearance: none; 
  -moz-appearance: none; 
  appearance: none; 
}

.arrow:disabled {
  color: #d0b197;
  cursor: not-allowed;
}

.month-name {
  color: #ee6e2d;
  font-size: 35px;
  font-weight: 700;
  margin-left: 20px;
  margin-right: 20px;
}