.containerGeneralL{
  overflow: hidden;
  max-height:100vh;
}

.containerPublicacionL {
  display: grid;
  grid-template-columns: 0fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;

  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 2vw;
  

  grid-template-areas:
    ". Foto Descripcion"
    ". recomendaciones recomendaciones"
    ". Comentarios Comentarios";
}

.FotoPublicacionL {
  grid-area: Foto;
}

.SubFotosPublicacionL{
  display: flex;
  flex-direction: row;
  margin-top: -26.7vw;
  justify-content: center;
  margin-left:-3vw
}


.TituloPublicacionL{
  height: 5vh;
  height: 2.5vw;
  width: 47.5vw;
}

.EstrellasPublicacionL{
  height: 1.8vw;
  width: 8.5vw;
}

.DescripcionTituloPublicacionL{
  height: 2vw;
  width: 8vw;
}

.DescripcionTextoPublicacionL{
  height: 1.5vw;
  width: 47.5vw;
}

.DescripcionPublicacionL {
  grid-area: Descripcion;
}

.recomendacionesPublicacionL {
  grid-area: recomendaciones;
}

.ComentariosPublicacionL {
  grid-area: Comentarios;
}

.BotonesSeccion1PublicacionL{
  display: flex;
  flex-direction: column;
}

.BotonesPublicacionL{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1vw;
}

.MetrosPublicacionL{
  height: 1.5vw;
  width: 5vw;
}

.PrecioPublicacionL{
  height: 3vw;
  width: 7vw;
}


.BotonesSeccion2PublicacionL{
  width: 20vw;
  //height: 11.5vh;
}

.BotonesSeccion3PublicacionL{
  height: 6vw;
  width: 5vw;
}

.tagsPublicacionL{
  width: 47.5vw;
  height: 8vw;
}

.recomendacionesL{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.reecomendacionL{
  height: 20vw;
  width: 14vw;
}

.mapaPublicacionL{
  width: 92vw;
  height: 40vw;
}

.comentariosL{
  width: 92vw;
  height: 5vw;
}