@import "../global.scss";

.centrarMR {
    margin-top: 50px;
    min-height: 80vh;
}
.cajaMR {
    width: 95%;
    margin: 0 auto;
    display: inline-block;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: fit-content;
    margin-top: 50px;
}

.cajaCalendarMr {
    display: flex;
    justify-content: center;
}

.cardMR {
    margin-bottom: 40px;
    margin-right: 1%;
}

.contenedorImagenMR {
    width: 40%;
    background-color: black;
    overflow: hidden;
    display: block;
    object-fit: cover;
}

.contenedorDentroCardMR {
    display: flex;
    flex-direction: row;
    height: auto;
}

.cajaTextMR {
    margin-left: 3%;
    max-width: 60%;
    min-width: 60%;
}

.tituloMR {
    margin-top: 4%;
    font-size: 1.5rem;
    font-weight: 400;
    color: #112b3c;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.botonCancelarMR {
    display: flex;
    flex-direction: row;
    bottom: 0;
    margin-left: 13%;
    justify-content: center;
    margin-bottom: 1em;
}
.margenMR {
    margin-top: 10%;
}
.fechaYHoraReservaMR {
    font-size: 1.5rem;
    font-weight: 400;
    color: #112b3c;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.direccionMR {
    font-size: 1rem;
    font-weight: 400;
    color: #112b3c;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.codigo {
    font-size: 1rem;
    font-weight: 400;
    color: #aaaaaa;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: -10%;
}

.codigo2 {
    font-size: 0.85rem;
    font-weight: 400;
    color: #112b3c;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.margenMR2 {
    margin-top: -1.1rem;
}

.cancelacionMR {
    margin-top: 10%;
    font-size: 0.7rem;
    font-weight: 400;
    color: #aaaaaa;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.alturaNoTienesRe {
    height: 70vh;
}

.containerText {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.letrita {
    margin-top: 0.5vh;
    font-size: large;
    color: #ff5722;
}

.letrita:hover {
    color: #ee6e2d;
}

.react-calendar__month-view__days__day {
    position: relative;
}

.cajaCalendarMr {
    .react-calendar__tile--now abbr {
        all: unset !important;
        font-family: "Source Sans Pro", sans-serif !important;
        border-radius: 4px !important;
        display: flex !important;
        font-size: 25px !important;
        justify-content: center !important;
        line-height: 28px !important;
        padding: 6.25px !important;
        text-align: center !important;
    }

    .react-calendar {
        margin-left: 0 !important;

        @include tabletPortrait {
            min-width: 450px;
            max-width: 450px;
        }
    }
    .react-calendar__month-view__days {
        position: relative;

        * {
            //border: 0.01px solid #a0a096;
            position: relative;
            overflow: hidden;
            box-sizing: border-box;
        }
    }

    .triangle {
        border: 0;
        position: absolute;
        top: -50%;
        left: 18%;
        width: 100%;
        height: 100%;
        z-index: 1;
        rotate: -90deg;

        @include desktop {
            top: -28%;
            left: 18%;
        }

        @include tabletLandSacpe {
            top: -20%;
            left: 18%;
        }

        @include tabletPortrait {
            top: -20%;
            left: 18%;
        }

        @include mobile {
            top: -5.4rem;
            left: 0.5rem;
            width: 5.5rem;
            height: 10rem;
        }
    }

    .triangle::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-left: 50px solid transparent;
        border-right: 50px solid transparent;
        border-bottom: 100px solid #ee6e2d;
    }

    .number {
        border: 0 !important;
        color: white;
        position: absolute;
        width: 100%;
        height: 100%;
        margin-left: -13%;
        margin-top: -50%;
        z-index: 3;
        font-size: medium;
        font-weight: 600;

        @include desktop {
            margin-left: -14%;
            margin-top: -70%;
        }

        @include tabletLandSacpe {
            margin-left: -17%;
            margin-top: -80%;
        }

        @include tabletPortrait {
            margin-left: -17%;
            margin-top: -80%;
        }

        @include mobile {
            color: white;
            font-weight: bold;
            margin-left: -0.7rem;
            margin-top: calc(-3.2rem + 0.2vw);
            font-size: calc(1rem - 0.4vw);
        }
    }

    .react-calendar__tile--active {
        background: #ee6e2d !important;
        //background: #ff6358 !important;
        border-radius: 0px !important;
    }
}

.react-calendar__navigation__label {
    @include mobile {
        font-size: small;
    }
}

[type="button"]:not(:disabled) abbr {
    all: unset;
    font-family: "Source Sans Pro", sans-serif !important;
    border-radius: 4px;
    //color:#424242;
    display: flex;
    font-size: 25px;
    justify-content: center;
    line-height: 28px;
    padding: 6.25px;
    text-align: center;
}

.react-calendar__month-view__days__day--weekend:not(.react-calendar__month-view__days__day--neighboringMonth){
    color: #112b3c;
}


.highlighted-day:not(:disabled){
    font-weight: bold;
}


/* Estilo cuando el botón está activo */
button.react-calendar__tile--active,
button.react-calendar__tile--rangeStart,
button.react-calendar__tile--rangeEnd,
button.react-calendar__tile--rangeBothEnds:active {
    color: white !important; /* Color de letra blanco para el estado activo */
}

button.react-calendar__tile.react-calendar__month-view__days__day:not(.react-calendar__month-view__days__day--neighboringMonth):not(.highlighted-day):not(.react-calendar__tile--active):not(.react-calendar__month-view__days__day--weekend){
    color: #112b3c !important;
}

button.react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575; /* Color de letra rojo para el estado inactivo */
    font-weight: normal;    
}

/* Estilo cuando el botón está inactivo */
button.react-calendar__tile.react-calendar__month-view__days__day.highlighted-day {
    color: #ee6e2d; /* Color de letra rojo para el estado inactivo */
    /* Otros estilos que quieras aplicar para el estado inactivo */
}

button.react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth.highlighted-day {
    color: #757575; /* Color de letra rojo para el estado inactivo */
    font-weight: normal;    
}

:root{
    --swiper-teme-color: #112b3c;
}