@import "../global.scss";
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.publicacion{
    width: 92vw;

    margin-left: 4vw;
    margin-right: 4vw;
    margin-top: 2vw;

    display: flex;
    flex-direction: row;


    height: auto;

    @include tabletPortrait{
        overflow: visible;
    }
    @include mobile{
        overflow: visible;
    }
}

.publicacion__contenedor1{
    display: flex;
    flex-direction: row;
    height: auto;
    background-color: pink;
}

.contenedorImagenes{
//    height: 150vh;
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';
    

    @include tabletPortrait{
        margin-bottom: 10vw;
    }
    @include mobile{
        margin-bottom: 10vw;
    }
}


.imagenPrincipalPublicacion{
    width: 35vw;
    height: 80%;
}

.flechaIzq{
    rotate : 90deg;
}

.carouse__anterior{
    all: unset;
    background-color: white;
    width: 10vw;
    height: 10vw;
}

.flechaR{
    width: 10vw;
    height: 10vw;
    rotate: 180deg;
}

.flechaL{
    width: 10vw;
    height: 10vw;
}

.imagenCarousel{
    width: 10vw;
    height: 10vw;
}

.aber{
    width:100px;
    height:100px;

}

.product-images-slider {

    .swiper-slide {
        padding-top: 100%;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }

    .swiper-button-prev {
        display: none;
        @include mobile{
            right: 0;
            color: #000;
        }
    }

    .swiper-button-next {
        display: none;
        @include mobile{
            right: 0;
            color: #000;
        }
    }
}

.contenedorSwiperResponsive{
    width: 41vw;
    height: auto;
    overflow: unset!important;
    @include tabletPortrait{
        width: 80vw;
        background-color: #fff;
    }
    @include mobile{
        width: 92vw;
        background-color: #fff;
    }
}

.contenedorSwiperResponsiveFin{
    width: 41vw;
    margin-top: 158%;
    height: auto;
    @include tabletPortrait{
        width: 80vw;
        background-color: #fff;
    }
    @include mobile{
        width: 92vw;
        background-color: #fff;
    }
}




.contenedorDescripcion{
    margin-left: 3vw ;
    width: 45vw;
    height: auto;
}

.tituloPublicacionP{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5vw;
    font-weight: 700;
    color: #112B3C;

    @include tabletPortrait{
        font-size: 3vw;
    }
    @include mobile{
        font-size: 3vw;
    }
}

.subtituloPublicacion{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5vw;
    font-weight: 400;
    color: #112B3C;

    @include tabletPortrait{
        font-size: 2.8vw;
    }
    @include mobile{
        font-size: 2.8vw;
    }
}

.textoPublicacion{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5vw;
    font-weight: 300;
    
    color: #112B3C;

    margin-left: 1vw ;

    @include tabletPortrait{
        font-size: 2.8vw;
    }
    @include mobile{
        font-size: 2.8vw;
    }
}

.parrafoPubli{
    white-space: pre-wrap;
   
}



.contenedorReserva{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 2vw;
}

.precioPublicacion{
    display: flex;
    flex-direction: row;
}

.botonReservaDes{
    all:unset;

    background-color:#d0b197;
    color: #fff;
    height: 1.5vw;
    font-size: 1vw;
    font-weight: 400;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    padding-left: 6vw;
    padding-right: 6vw;

    @include tabletPortrait{
        height: 5vw;
        font-size: 2.5vw;
        font-weight: 400;
        padding-top: 0.5vw;
        padding-bottom: 0.5vw;
        padding-left: 8vw;
        padding-right: 8vw;
    }
    @include mobile{
        height: 5vw;
        font-size: 2.5vw;
        font-weight: 400;
        padding-top: 0.5vw;
        padding-bottom: 0.5vw;
        padding-left: 8vw;
        padding-right: 8vw;
    }
}

.botonReservaDes:hover{
    cursor: no-drop;
}

.botonReserva{
    all:unset;



    background-color:#EE6E2D;
    color: #fff;
    height: 1.5vw;
    font-size: 1vw;
    font-weight: 400;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    padding-left: 6vw;
    padding-right: 6vw;

    @include tabletPortrait{
        height: 5vw;
        font-size: 2.5vw;
        font-weight: 400;
        padding-top: 0.5vw;
        padding-bottom: 0.5vw;
        padding-left: 8vw;
        padding-right: 8vw;
    }
    @include mobile{
        height: 5vw;
        font-size: 2.5vw;
        font-weight: 400;
        padding-top: 0.5vw;
        padding-bottom: 0.5vw;
        padding-left: 8vw;
        padding-right: 8vw;
    }
}

.botonReserva:hover{
    border: 1px solid;
    border-color:rgba(0, 0, 0, 0.35);
 }
/*
.botonReserva:focus{
    background-color: pink;
}*/


.precioP{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1.8vw;
    
    color: #112B3C;

    
    @include tabletPortrait{
        font-size: 2.8vw;
    }
    @include mobile{
        font-size: 2.8vw;
    }
}

.metrosP{
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-size: 1.1vw;

    color: #112B3C;

    @include tabletPortrait{
        font-size: 2vw;
    }
    @include mobile{
        font-size: 2vw;
    }
}

.porHoraP{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size:1vw;
    margin-left: 5px;
    margin-top: 0.6vw;
    color: #BDBDBD;

    @include tabletPortrait{
        font-size: 2vw;
    }
    @include mobile{
        font-size: 2vw;
    }
}


.botonLikeP{
    all: unset;
    width: 10vw;
    height: 10vw;
    margin-left: 1vw;
    
    margin-top: -4.4vw;
    margin-right: -3vw;

    @include tabletPortrait{
        width: 20vw;
        height: 20vw;

        margin-top: -8vw;
        margin-right: -3vw;
    }
    @include mobile{
        width: 20vw;
        height: 20vw;

        margin-top: -8vw;
        margin-right: -3vw;
    }

}

.contenedorPrecioP{
    margin-top: -1vw;
}


.ratingP{
    margin-left: 0.5vw;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 1vw;
    line-height: 2.3vw;

    color: #FFA800;

    @include tabletPortrait{
        font-size: 2vw;
        line-height: 4.8vw;
    }
    @include mobile{
        font-size: 2vw;
        line-height: 4.8vw;
    }
}

.tagsP{
    max-width: 90%;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    font-size: 1vw;
    line-height: 100%;
    /* or 25px */

    letter-spacing: 0.1em;
    color: #0077C5;

    @include tabletPortrait{
        font-size: 2vw;
        line-height: 3vw;
    }
    @include mobile{
        font-size: 2vw;
        line-height: 3vw;
    }
}

.recomendacionesP{
    margin-top: 5vw;
    margin-left: 4vw;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1.6vw;


    color: #112B3C;

    @include tabletPortrait{
        font-size: 2.8vw;
        line-height: 3vw;
        margin-left: 1vw;
    }
    @include mobile{
        font-size: 2.8vw;
        line-height: 3vw;
    }
}


.contenedorComentariosP{
    margin-top: 1vw;
    display:flex;
    justify-content: center;
}

.espacioP{
    min-height: 10vw;
    width: 100%;

}

.contenedorCardsP{
    margin-top: 1vw;
    display:flex;
    justify-content: center;
    height: 16vw;

    @include tabletPortrait{
        height: 38vw;
    }
    @include mobile{
        height: 38vw;
    }

  
    
}

.imagenCardP{
    height: 7.4vw;
    @include tabletPortrait{
        height: 10vw;
    }
    @include mobile{
        height: 10vw;
    }
}

.cardP{
    margin-left: 1vw;
    width: 17.5vw;
    height: 15vw;

    @include tabletPortrait{
        width: 30vw;
        height: 30vw;
    }
    @include mobile{
        width: 25vw;
        height: 25vw;
    }
}

.precioCardP{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1.7vw;
    
    color: #112B3C;

    @include tabletPortrait{
        font-size: 2.8vw;
    }
    @include mobile{
        font-size: 2.8vw;
    }
}

.comentariosP{

    max-width: 70vw;

    border-radius: 50px;
    
    background-color:#EE6E2D;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;

    margin-bottom: 1vw;
    display: inline-block;
    display: inline-flex;
    flex-direction: column;
                 
    
}

.userP{
    margin-left: 2vw;
    margin-right: 2vw;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 1vw;

    @include tabletPortrait{
        font-size: 1.7vw;
        margin-left: 2.6vw;
    }
    @include mobile{
        font-size: 1.7vw;
        margin-left: 2.6vw;
    }
}

.comentarioUserP{
    margin-left: 3vw;
    margin-right: 3vw;
    

    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    font-size: 1.4vw;

    @include tabletPortrait{
        font-size: 2vw;
    }
    @include mobile{
        font-size: 2vw;
    }

}

.contenedorDeComentariosP{
    /*display:flex;
    justify-content: center;*/
    margin-top: 1vw;
    margin-left: 4vw;
    color:#fff;

    @include tabletPortrait{
        margin-left: 3vw;
    }
    @include mobile{
        margin-left: 3vw;
    }
    
   
}

.textCardP{
    font-family: 'Roboto', sans-serif;
    font-size: 0.75vw;
    line-height: 1.1vw;
    font-weight: 400;
    color:rgba(0, 0, 0, 0.6);

    @include tabletPortrait{
        font-size: 1.5vw;
        line-height: 1.9vw;
    }
    @include mobile{
        font-size: 1.5vw;
        line-height: 1.9vw;
    }
}

.barraComentariosP{
    border: 0px ;
    padding-left:2.3vw ;
    font-size: 1.1vw ;


    width: 10vw;


    @include tabletPortrait{
        font-size: 2vw ;
    }
    @include mobile{
        font-size: 2vw ;
    }
  
    
}

.centroBarraP{
    position: block;
    width: 44vw;
    gap: 40px;

    

}

.noSuperponer{
    position:relative;
    display:block;

}

.comentarioPubli{
    width: 100%;
    border:none;
    padding-left:2.3vw;
    font-size: 20px;

    @include  tabletLandSacpe{
        font-size: 10px; 
    }
    @include tabletPortrait{
        font-size: 12px; 
    }
    @include mobile{
        font-size: 30px; 
        font-size: 10px;
    }


}

.comentarioPubli:focus{
    border:none;
    outline-color: #BDBDBD;
}


.comentarioP{
    width:70vw;
    height: 50px; 
    margin-left: -10vw;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);

    @include desktop{
        height: 40px; 
    }
    @include  tabletLandSacpe{
        border: 1px solid;
        border-color: #112B3C;
        height: 30px; 
    }
    @include tabletPortrait{
        border: 1px solid;
        border-color: #112B3C;
        margin-top:5px;
        height: 30px; 
        width: 63vw;
        margin-left:-5vw;
    }
    @include mobile{
        border: 1px solid;
        border-color: #112B3C;
        margin-top:5px;
        height: 25px; 
        width: 63vw;
        margin-left:-5vw;
    }
}

.comentarioPError{
    border: 1px solid;
    color: #e66666;
}


.enviarComentarioP{
    all:unset;
    border-radius: 20px;

    background-color:#EE6E2D;
    color: #fff;
    height: 1.5vw;
    font-size: 1vw;
    font-weight: 400;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    padding-left: 2vw;
    padding-right: 2vw;
    margin-left: 18vw;

    margin-top: 5px;


    @include desktop{
        margin-top: 10px;
    }

    @include  tabletLandSacpe{
        margin-top: 11px;
    }

    @include tabletPortrait{
        height: 3vw;
        font-size: 2vw;
        font-weight: 400;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        padding-left: 4vw;
        padding-right: 4vw;
        margin-top: 5px;
        
        
    }
    @include mobile{
        height: 3vw;
        font-size: 2vw;
        font-weight: 400;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        padding-left: 4vw;
        padding-right: 4vw;
    }
}

.enviarComentarioP:hover{
    
    border: 1px solid; 
    border-color:rgba(0, 0, 0, 0.35);
   // box-shadow: 0 0 0 3px #fff inset;
 }

.no_hay_comentarios{
    font-size:1.5vw;
    color:#BDBDBD;

}

.centrarPubli{
    display: flex;
    justify-content: center;
    margin-top: 14vw;
}

.errorComentario{
    display: block;
    color: #e66666;
    margin-left: 5vw;
    margin-bottom: 2vw;
}

.meSeguis{
    position: fixed;
    top: 0%;
}

.margenLeft{
    margin-left: -1vw;
}

.cajaPubli{
    width: 90vw;
}

.margenPubli{
    padding-top:9vh
}

.centrarDivPuCarg{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #de854a;
    
}

.loader{
    height: 91.7vh;
}

.divGeneralCargarPu{
    overflow: hidden;
    background-color: #de854a;
}

.posicionAlerta{
    
    position: fixed;
    top: 90vh;
    left: 2vw;
    z-index: 1000000000000000;

}


.errorPagosPu{
    color: #e66666;
    font-size: 0.8vw;
    margin-left: 2vw;
    margin-top: -2vw;
    margin-bottom: 4vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include tabletPortrait{
        font-size: 1.5vw;
        margin-top: -4vw;
        margin-left: -3vw;
    }

    @include mobile{
        font-size: 1.5vw;
        margin-top: -4vw;
        margin-left: -3vw;
    }
}

.textoCancelar{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1vw;
    font-weight: 300;
    white-space: pre-wrap;
    
    color: #aaaaaa;


    @include tabletPortrait{
        font-size: 2.3vw;
    }
    @include mobile{
        font-size: 2.3vw;
    }
}

.contenedorMapa{
    display: flex;
    justify-content: center;
    align-items: center;
}

div.place-name{
    display: none !important;
    position: relative;
}

.wp_cotainer {
    margin-left: 1vw ;
}

.wp_reservar_container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.margen_existe_wp{
    margin-top: 3vw;
}