@import "../global.scss";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

.navBarBarra {
    display: flex;

    width: 100%;
    height: 70px;
    left: 0px;
    top: 0px;

    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);

    
}

.navegacionBarra1 {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    justify-content: space-evenly;
}

.navBarBarra1 > * {
    flex: 1;
}

.centroBarra {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50vw;
    gap: 40px;
}



.busquedaU {
    width: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
}


.puertaBarra {
    height: 47px;
    width: 30px;
    height: 5.5vh;
    width: 3.5vh;
    margin-left: -5vw;

    display: none;

    @include tabletPortrait {
        display: block;
    }

    @include mobile {
        margin-left: -7vw;
        display: block;
    }
}

.logoBarra {
    width: 123px;
    height: 42px;
    left: 110px;
    top: 14px;

    @include tabletPortrait {
        display: none;
    }

    @include mobile {
        display: none;
    }
}

.searchBarra {
    background: #ffffff !important;
    border: 0px !important;
    padding-right: 2.3vw !important;
}

.searchBarra2 {   
    border: 0px !important;
    padding-left: 2.3vw !important;
    font-size: 2.1vh !important;
    height: 100% !important;

    @include mobile {
        font-size: 1.8vh !important;
    }

}

.lupaBarra {
    width: 2.5vh;
    height: 2.5vh;
    display: flex;
    align-items: center;
    justify-content: left;
}

.gris {
    color: #bdbdbd;
}

.loaderBarra {
    margin-left: 4rem;
    @include desktop {
        margin-right: -15%;
    }

    @include tabletLandSacpe {
        display: none;
    }

    @include tabletPortrait {
        display: none;
    }

    @include mobile {
        display: none;
    }
}

.publicaDeshabilitado {
    border-color: transparent;

    padding: 10px 20px;

    margin-top: 10px;

    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #ffffff;

    background: #ee6e2d;

    cursor: default !important;

    @include desktop {
        padding: 10px 5px;
        margin-right: 50px;
    }

    @include tabletLandSacpe {
        display: none;
    }

    @include tabletPortrait {
        display: none;
    }

    @include mobile {
        display: none;
    }
}



.publica-barra {
    border-color: transparent;

    padding: 10px 20px;

    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #ffffff;

    background: #ee6e2d;

    @include tabletLandSacpe {
        display: none;
    }

    @include tabletPortrait {
        display: none;
    }

    @include mobile {
        display: none;
    }
}

.publica-barra:hover {
    border-color: rgba(0, 0, 0, 0.35);
}

.centro2 {
    display: none;

    @include desktop {
        display: flex;
    }
}

.drawer-barra {
    display: none;
    flex-direction: row;
    justify-content: right;
    margin: 5vw;

    @include tabletLandSacpe {
        display: flex;
    }

    @include tabletPortrait {
        display: flex;
    }

    @include mobile {
        display: flex;
    }
}

.animation-container-barra {
    height: 30px;
    width: 30px;
    display: none;

    @include tabletLandSacpe {
        display: flex;
    }

    @include tabletPortrait {
        display: flex;
    }

    @include mobile {
        display: flex;
    }
}


.centroBarra1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;


    @include tabletLandSacpe {
        display: none;
    }

    @include tabletPortrait {
        display: none;
    }

    @include mobile {
        display: none;
    }
}

.letras-barra {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #112b3c;
    flex: none;

    padding-top: 12px;

    flex-grow: 0;
    text-decoration: none;

}