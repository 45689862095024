.about-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ee6e2d;
    z-index: 1 !important;
    color: white;
    padding: 2rem;
    overflow: hidden !important;
    overflow-y: hidden !important;
}

.about-image {
    width: 50%;
    margin-bottom: 2rem;
}

.about-text {
    text-align: center;
    width: 80%;
    overflow: hidden;
    overflow-x: hidden !important;
}

.cross {
    position: absolute;
    top: 8%;
    left: 95%;
    width: 50px;
    height: 50px;
    font-size: 70px;
    font-weight: 10;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.cross {
    animation: blink 1s infinite;
}

@keyframes blink {
    50% {
        opacity: 0.5;
    }
}
