@import "../global.scss";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

.publica2 {
    all: unset;
    border-color: transparent;

    //padding: 10px 20px;
    padding: 1.2vh 2vw;
    /*  padding-left: 5px;
    padding-right: 5px;
    padding-top: 1px;
    padding-bottom: 15px;*/

    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 120%;
    line-height: 100%;

    color: #ffffff;
    text-align: center;

    //Para 1080p
    margin-left: 0.5vw;
    margin-right: 3vw;

    //Para 720p
    /*margin-left: -2vw;
    margin-right: 4vw;*/

    box-shadow: 0px 4px 4px rgba(57, 61, 66, 0.25);

    display: flex;
    justify-content: center;
    align-items: center;
    background: #ee6e2d;
}

.publica2:hover {
    border-color: rgba(0, 0, 0, 0.35);
}

.botonesFiltro {
    width: 100%;
    height: auto;
    padding-top: 3vh;
    display: flex;
    justify-content: center;
}

.animacionFiltroInicio {
    width: 2.5vh;
    height: 2.5vh;
    margin-left: 1vw;
    margin-right: -2vh;
    margin-bottom: 3px;

    @include tabletLandSacpe {
        margin-right: -0.5vh;
    }

    @include tabletPortrait {
        margin-right: -0.5vh;
    }

    @include mobile {
        margin-right: -0.5vh;
    }
}

.animacionFlechaInicio {
    width: 4vh;
    height: 4vh;
    margin-left: 0.5vw;
    margin-right: -2vh;

    @include tabletLandSacpe {
        margin-right: -1vh;
    }

    @include tabletPortrait {
        margin-right: -1vh;
    }

    @include mobile {
        margin-right: -1vh;
    }
}

.j {
    all: unset;
}

.filtros {
    width: 100%;
    height: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 2vh;
    background-color: #112b3c;

    @include mobile {
        flex-direction: column;
        margin-top: -5%;
    }
}

.cajaUbicacionFiltros {
    width: 80% !important;
    margin-bottom: 20vh;

    @include mobile {
        margin-bottom: 2vh;
    }
}

.ubicacionFiltro {
    //Mueve a la derecha si aumentas lo de abajo
    margin-left: 4.5%;

    font-family: "Source Sans Pro";
    font-style: bold;
    font-weight: 400;
    font-size: 2.1vh;
    line-height: 2.7vh;

    color: #fff;
}

.asd {
    background-color: #112b3c;
}

.boxTagsInicio {
    height: auto;
    margin-top: 5vh;
    display: flex;
    justify-content: center;

    border-color: #bdbdbd;
    border-style: solid none;
    border-width: 1px 0px;

    border-left: none;
    border-right: none;
    border-top: none;

    margin-left: -5vw;
}

.boxTagsInicio2 {
    margin-top: -1vh;
    border-color: #bdbdbd;
    border-style: solid none;
    border-width: 1px 0px;

    border-left: none;
    border-right: none;
    border-top: none;
}

.tagsClass {
    margin-bottom: 5vh;
}

.tagInputClass {
}

.tagInputFieldClass {
    display: none;
}

.selectedClass {
}

.tagClass {
    background-color: #112b3c;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 50px;
    margin-left: 15px;
    color: white;
}

.removeClass {
    all: unset;
    margin-left: 1vw;
    width: 1vw;
    font-size: 18px;
}

.suggestionsClass {
}

.activeSuggestionClass {
}

.editTagInputClass {
}

.editTagInputField {
}

.clearAllClass {
}

.loSentimos {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70%;
    width: 100%;
    //bold
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 2.5vh;
    line-height: 2.7vh;
    text-align: center;
    color: #112b3c;
}

.separacion {
    margin-top: 5vh;
    margin-bottom: 5vh;
    font-size: 14px;
    color: #d3d3d3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader2 {
    width: 48px;
    height: 48px;
    border: 5px solid #d3d3d3;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 0.5s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader3 {
    width: 25px;
    height: 25px;
    border: 3px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 0.5s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.cross2 {
    position: absolute;
    top: 5px; /* Ajusta este valor según tu preferencia para la distancia desde la parte superior */
    right: 20px; /* Ajusta este valor según tu preferencia para la distancia desde la derecha */
    width: 50px;
    height: 50px;
    font-size: 70px;
    font-weight: 10;
    cursor: pointer;
}

/* Regla de media query para ajustar el tamaño en pantallas más pequeñas */
@media (max-width: 768px) {
    .cross2 {
        width: 30px;
        height: 30px;
        font-size: 40px;
    }
}

.cross2 {
    animation: blink 1s infinite;
}

@keyframes blink {
    50% {
        opacity: 0.5;
    }
}


.listadoPubli{
    min-height: 100vh;
}