@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
@import "../global.scss";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.cajaCalendario {
    width: 100%;
    height: 100%;
    padding-bottom: 4vh;
    display: flex;
    justify-content: center;
}

.horasReservadas {
    color: #112b3c;
}

.monto {
    font-family: "Source Sans Pro", sans-serif;
    color: #112b3c;
    font-weight: 300;
    margin-left: 0.5vw;
    font-size: 30px;
    margin-top: 5px;

    @include tabletPortrait {
        font-size: 20px;
    }

    @include mobile {
        font-size: 20px;
    }
}

.cajaHoraYPrecio {
    display: flex;
    flex-direction: row;
}

.reservaRealizada {
    border-color: transparent;

    padding: 15px 100px;

    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 3vw;

    margin-top: 3vw;
    margin-bottom: 3vw;

    color: #ffffff;

    background: #ee6e2d;

    border-radius: 50px;

    @include tabletPortrait {
        margin-top: 6vw;
        font-size: 25px;
    }
    @include mobile {
        margin-top: 25vw;
        font-size: 20px;
    }
}

.reservaRealizada:hover {
    border-color: rgba(0, 0, 0, 0.35);
}

.errorHorasPu {
    margin-bottom: -28px;
    color: red;
}

.imagenReserva {
    display: flex; /* Utiliza flexbox para centrar la imagen */
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    overflow: hidden; /* Oculta cualquier parte de la imagen que exceda el contenedor */
    margin: auto; /* Centra el contenedor en la página */
    border-radius: 20px; /* Bordes redondeados para el contenedor */
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75); /* Sombra alrededor del contenedor */
    margin-top: 2vh; /* Margen superior */
    width: fit-content; /* Ajusta el ancho al contenido interno */
    height: fit-content; /* Ajusta la altura al contenido interno */

    width: 50vw;
    height: 50vh;
    margin-top: 4vh;
    margin-bottom: 4vh;
}

.imagenReserva img {
    width: 100%; /* Hace que la imagen llene el contenedor */
    height: 100%; /* Mantiene la proporción de la imagen */
    border-radius: 20px; /* Bordes redondeados para la imagen */
    object-fit: cover; /* Ajusta la imagen al contenedor */
}


.fondoPuLoader {
    margin-bottom: -30vw;
    overflow: hidden !important;
}

.cajaPrincipalReservaLoader {
    margin-top: 3vw;
    margin-left: 3vw;
    margin-right: 3vw;
    margin-bottom: 10vw;
    border-radius: 50px;
    background: #ffffff;
    box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
    height: 70vh;
    width: 94%;
    display: flex;
    justify-content: center;
    align-items: center;
    position:absolute;
}
