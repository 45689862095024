@import "../global.scss";
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

.product-images-slider {
    .swiper-slide {
        padding-top: 100%;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }

    .swiper-button-prev {
        right: 0;
        //color: #000;
    }

    .swiper-button-next {
        right: 0;
       // color: #000;
    }
}

.product-images-slider-thumbs {
    .swiper-slide {
        cursor: pointer;

        &-thumb-active {
            border: 1px solid;
            border-color: #ff0000;
        }
    }

    &-wrapper {
        width: 100%;
        padding-top: 100%;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }


}

.prev{
    flex: auto;
    width: 4vw;
    height: 4vw;

}

.next{
    width: 4vw;
    height: 4vw;
    margin-left: 20vw;
    rotate: 180deg;

}



.nada{
    all:unset;
}

.prueba{
    max-width: 25vw;
    margin-top: 10px;


    @include tabletPortrait{
        max-width: 55vw;
    }
    @include mobile{
        max-width: 55vw;
    }
}

.prueba2{
    max-height: 5vw;
    //background-color: #000;
}

.principalS{
    /*height: 83vh;*/
}

.prueba3P{
    display: flex;
    justify-content: space-around;
    margin-top: -5.6vw;


    @include tabletPortrait{
        margin-top: -10.5vw;
        margin-left: -10vw;
        margin-right: -10vw;
    }
    @include mobile{
        margin-top: -10.5vw;
        margin-top: -10.5vw;
        margin-left: -10vw;
        margin-right: -10vw;
    }
}

.fotitos{
   //margin-bottom: 100px; 
  // background-color: #000;
}


.imagenPubliSw{
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
   // background-color: #000;
}
