.inicioL{
    max-height:100vh;
    overflow: hidden;
}

.imagenSkeleton{
    margin-left: 0.5vw;
    width: 90%;
    height: 95%;

    display: flex;
}

.skeletonImage{
    flex: 1;
    margin: 0.5vw;
}


.botonesInicioLoader{
    width: 100%;
    height: auto;
    padding-top: 3vh;
    display: flex;
    justify-content:center;

    border-width: 1px 0px;
    border-style: solid;
    border-color: #BDBDBD;

    border-left: none;
    border-right: none;
    border-top: none;
}
