@import "../global.scss";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.colorFondoIn {
    background-color: #ee6e2d; //#EE6E2D;//#FF864A; //#FFA420;
    width: 100vw;
    height: 65rem;

    /*
    background-color:#ffa799;
    background-image:
    radial-gradient(at 28% 91%, hsla(344,70%,77%,1) 0px, transparent 50%),
    radial-gradient(at 38% 56%, hsla(20,100%,65%,1) 0px, transparent 50%),
    radial-gradient(at 5% 85%, hsla(36,100%,56%,1) 0px, transparent 50%),
    radial-gradient(at 5% 85%, hsla(36,100%,56%,1) 0px, transparent 50%);*/

    background-color: #ffa799;
    background-image: radial-gradient(
            at 8% 65%,
            hsla(16, 75%, 73%, 1) 0px,
            transparent 50%
        ),
        radial-gradient(at 31% 80%, hsla(36, 100%, 56%, 1) 0px, transparent 50%),
        radial-gradient(at 84% 74%, hsla(22, 73%, 66%, 1) 0px, transparent 50%),
        radial-gradient(at 26% 26%, hsla(16, 91%, 65%, 1) 0px, transparent 50%),
        radial-gradient(at 85% 20%, hsla(20, 100%, 65%, 1) 0px, transparent 50%),
        radial-gradient(at 50% 46%, hsla(344, 70%, 77%, 1) 0px, transparent 50%);
    /*
    background: rgb(248,163,79);
    background: linear-gradient(0deg, rgba(248,163,79,1) 0%, rgba(251,141,106,1) 100%);*/
    scroll-snap-align: start;
}

.ContainerCardIn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 55rem;
    //height: 683px;
    //height: 39.3vw;
}

.cardIn {
    background-color: #fefefe;

    //width: 35vw;
    width: 630px;
    //height: 30vw;

    height: auto;
    overflow: hidden;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    text-align: center;

    @include tabletPortrait {
        width: 430px;
        height: auto;
    }

    @include mobile {
        width: 330px;
        height: auto;
    }
}

.tituloIn {
    padding-top: 2vw;
    font-family: "Source Sans Pro", sans-serif;
    color: #112b3c;
    font-weight: 400;
    margin-left: 1vw;
    margin-right: 0.5;
    font-size: 30px;
    margin-left: 1vw;

    @include mobile {
        font-size: 20px;
    }
}

.subtituloIn {
    font-family: "Source Sans Pro", sans-serif;
    color: #112b3c;
    font-weight: 300;
    margin-left: 1vw;
    margin-right: 0.5;
    font-size: 30px;
    margin-left: 1vw;

    @include mobile {
        font-size: 20px;
    }
}

.separadorIn {
    width: 80%;
    height: 2px;
    background-color: #ee6e2d; //#BDBDBD;
}

.centradoIn {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100%;
    height: 100%;
}

.botonesRedesIn {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 65%;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.textoIn {
    font-size: 15px;
}

.olvidaste {
    display: flex;
}

.registrate {
    margin-bottom: 20px;
    display: flex;
}

.recaptcha{
    margin-top: 20px;
}

.errorCaptcha{
    color: #e66666;
    font-family: Arial, sans-serif;
    font-weight: bold;
}