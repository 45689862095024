.botonPR2{
  margin-top: 20vh;
  height: 50px;
  margin-bottom: 20px;
}
.botonPR{
  margin-top: 10.5rem;
  animation:fadeIn 1s;
}
.loader4 {
  //position: absolute;
  display: flex;
  flex-direction: column;
  content: '';
  top: 31rem;
  left: calc(50% - 2.5rem);
  width: 5rem;
  height: 5rem;
  //border: 5px solid lightBlue;
  border: 5px solid #FFF;
  border-radius: 50%;
  overflow: hidden;
  transition: 0.3s;
  box-shadow: 0rem 0rem 0.6rem rgba(0,0,0,0.3);
  animation: spin 0.7s linear infinite;
}
.success {
background: #1AA260;
border-color: #1AA260;
}
.fail {
background: #ff3e30;
border-color: #ff3e30;
}
.loader4.success{
  &::before {
    position: relative;
    height: 7px;
    width: 2.5rem;
    position: absolute;
    top: 65%;
    left: 44%;
    background-color: #FFF;
    transform: rotate(-45deg);
    transform-origin: 0% 50%;
    border-radius: 5px;
    animation: baseGrow 0.7s ;
    content: '';
  }
  
  &::after {
    height: 7px;
    width: 1.5rem;
    position: relative;
    top: 65%;
    left: 50%;
    background-color: #FFF;
    transform: rotate(-135deg);
    transform-origin: 0% 50%;
    border-radius: 5px;
    animation: tipGrow 0.7s;
    content:'';
  }  
}
.loader4.fail {
   &::before {
    position: relative;
    width: 3rem;
    height: 0.5rem;
    background: #FFF;
    transform-origin: 50% 50%;
    top: calc(50% - 0.25rem);
    right: calc(18% - 1.5rem);
    transform: rotate(45deg);
    content: '';
    border-radius: 10px;
    animation: leftIn 0.3s linear;
    content: '';
  }
  
  &::after {
    position: relative;
    width: 3rem;
    height: 0.5rem;
    background: #FFF;
    transform-origin: 50% 50%;
    top: calc(40% - 0.25rem);
    left: calc(50% - 1.5rem);
    transform: rotate(-45deg);
    content: '';
    border-radius: 10px;
    border-color: black;
    animation: rightIn 0.3s linear;
    content:'';
  } 
}
.loader4.fail {
  animation: pop2 1.2s ease-in-out;
}

.loader4.success{
  animation: pop 1.2s ease-in-out infinite;
}

@keyframes spin {
0% {
  transform: rotate(0deg);
      border-top: 5px solid #EE6E2D;
}
100% {
  transform: rotate(360deg);
      border-top: 5px solid #EE6E2D;

}
}

@keyframes tipGrow {
0% {
  width: 0px;
  left: 0;
  top: 0;
}
25% {
  width: 0px;
  left: 0;
  top: 0; 
}
50% {
  top: 0%;
  left: 0%;
  width: 0;
}
75% {
  top: 0%;
  left: 0%;
  width: 0rem;
}
100% {
  top: 65%;
  left: 50%;
  width: 1.5rem;
}
}

@keyframes baseGrow {
0% {

  width: 0;
}
90% {
  width: 0;
}
100% {

  width: 2.5rem;
}
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);    
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pop2 {
0% {
  transform: scale(1);
}
80% {
  transform: scale(1);    
}
100% {
  transform: scale(1.1);
}
}

@keyframes leftIn {
0% {
  left: 0;
  top: 0;
  width: 0;
}
100% {
  top: calc(50% - 0.25rem);
  left: calc(50% - 1.5rem);
  width: 3rem;
}
}

@keyframes rightIn {
0% {
  rigth: -10;
  top: 0;
  width: 0;
}
100% {
  top: calc(40% - 0.25rem);
  left: calc(50% - 1.5rem);
  width: 3rem;
}
}

.centrarError{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3.6rem;
  margin-bottom: -8rem;
}