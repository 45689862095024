@import "../global.scss";
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');


.cajaCompleta{
    /*overflow: hidden;*/
    //scroll-snap-type: y mandatory;
    overflow-y: scroll;
    overflow-x: hidden;
    /* scrollbar-width: none; //esconde la barra for firefox
    &::-webkit-scrollbar { //esconde la barra for chrome
        display: none;
    }*/
    height: 100vh;

}

.cajaCompleta2{
    /*overflow: hidden;*/
    //scroll-snap-type: y mandatory;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh
}

.caja1{
    height:100vh;
    width:100%;
}

.caja1NoScroll{
    height:100vh;
    overflow: hidden;
}

.caja0{
    scroll-snap-align: end;

    height:100vh;
    width:100%;
    //background-image:url('../assets/Fondo_entero.png');
    background-image:url('../assets/fondo.webp');
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;

}


.titulo{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 8vh;
    font-weight: 700;
    line-height: 0.95em;
    letter-spacing: 0em;
    text-align: center;
    
    color: #112B3C;

    overflow: hidden;
    padding-bottom: 2.2vh;
    padding-top: 0.5vh;

    @include mobile{
        font-size: 8vw;
        padding-bottom: 1.15vh;
    }
}

.titulo2{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 8vh;
    font-weight: 700;
    line-height: 5vh;
    letter-spacing: 0em;
    text-align: center;
    
    color: #112B3C;

    overflow: hidden;

    padding-top: 0.5vh;
    padding-bottom: 2vh;

    @include mobile{
        font-size: 8vw;

    }
}






.navBar{
    display:flex;

    width: 100%;
    height: 70px;
    left: 0px;
    top: 0px;
    
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
    
}



.navegacion1{
    display:flex;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    justify-content: space-between;
    margin-left: 5vw;
}

.navegacion1 > *{
    flex:1;
}

.centro{

    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;

    
    @include tabletLandSacpe {
        display: none;
    }

    @include tabletPortrait {
        display: none;
    }

    @include mobile {
        display: none;
    }
}

.centro1{

    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;


    @include tabletLandSacpe {
        display: none;
    }

    @include tabletPortrait {
        display: none;
    }

    @include mobile {
        display: none;
    }
}

.centro2{
    display: none;

    @include desktop{
        display: flex;
    }
}

.logoLink{
    display: flex;
    flex-direction: row;
}

.publica{
    border-color: transparent;

    padding: 10px 20px;

    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #FFFFFF;
    
    background: #EE6E2D; 

    @include desktop {
        margin-left: 5vw;
    }

    @include tabletLandSacpe {
        display: none;
    }

    @include tabletPortrait {
        display: none;
    }

    @include mobile {
        display: none;
    }
}



.publica:hover{
   border-color:rgba(0, 0, 0, 0.35);
}

.letras{

    all: initial;
    
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #112B3C;
    flex: none;

    flex-grow: 0;
    text-decoration: none;
    cursor:pointer;
}

.letras2{
    
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #112B3C;
    flex: none;

    padding-top: 12px;


    flex-grow: 0;
    text-decoration: none;

    @include desktop{
        display: none;
    }

}

.letras3{
    
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #112B3C;
    flex: none;


    flex-grow: 0;
    text-decoration: none;

    display: none;

    @include desktop{
        display: flex;
    }

}

.caja2{

    height:80.5vh;
    width:100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.caja3{
    padding-top:3vh;
    background: rgba(255, 255, 255, 0.8);
    min-height:66vh;
    width:53vw;
    top: auto;
    margin-top: -0.2vh;

    @include mobile{
        padding: 8%;
        height:55vh;
        width:85vw;
    }
}

.buscar{

    border-color: transparent;
    padding: 1vw 2vw;

    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 2vw;
    line-height: 2vw;
  

    color: #FFFFFF;
    
    
    width: 16.8vw;
    height: 5vw;

    background: #EE6E2D; 

    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10px;

    @include mobile{
        font-weight: 700;
        font-size: 5vw;
        line-height: 8vw;

        width: 40vw;
        height: 12vw;
    }
}

.buscar:hover{
    border-color:rgba(0, 0, 0, 0.35);
}

.caja4{
    display: flex;
    justify-content: center;
}







.caja5{

    display: block;
    flex-direction: row;
    justify-content: center;
}

.dropdownsito{

 
    margin-right: auto;
    margin-left: auto;
    width: 100px !important;
    height: 100px !important;
}

.cajaUbicacion{

    width: 80%!important;
    margin-right: auto;
    margin-left: auto; 
}

.ubicacion{
    display: flex;
    //Mueve a la derecha si aumentas lo de abajo
    margin-left: 4.5%;
    height:100%;
    
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 2.1vh;
    line-height: 2.7vh;

    color: #112B3C;
 
}

.search3{
    display: flex;
    margin-top:-1.4vh;
    width:95% !important;
    height: 10vh !important;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.12);
    margin-right: auto;
    margin-left: auto;
}

.cajaUbicacion2{    
    width: 80% !important;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 3vh;
}

.profesiones{

    width: 95%;
    margin-right: auto;
    margin-left: auto;
    margin-top:-1.4vh;
/*
    
    width:95% !important;
   
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.12);
    margin-right: auto;
    margin-left: auto;*/
}

.fa-bars{
    color:#FFFFFF

}

.animation-container{
    height: 30px;
    width: 30px;
    display:none;

    @include tabletLandSacpe {
        display: flex;
    }

    @include tabletPortrait {
        display: flex;
    }

    @include mobile {
        display: flex;
    } 

}

.logoPuerta{
    height: 42px;
    width: 28px;
    display: none;
    
    @include tabletLandSacpe {
        display: flex;
    }

    @include tabletPortrait {
        display: flex;
    }

    @include mobile {
        display: flex;
    } 
    
}


.logo{
    width: 123px;
    height: 42px;
    left: 110px;
    top: 14px;
}

.Lista{
    padding-top: 10vh;
    
}

.search{
    background: #FFFFFF !important;
    border: 0px !important;
    padding-right:2.3vw !important;
}

.search2{
    border: 0px !important;
    padding-left:2.3vw !important;
    font-size: 2.1vh !important;   
}