@import "../global.scss";
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');


.contenedorPublicacion{
    display: flex;

    border-width: 1px 0px;
    border-style: solid;
    border-color: #BDBDBD;

    border-left: none;
    border-right: none;
    border-top: none;

    width: 100%;
    //height: 18vh;
    height: 220px;

    @include mobile{
        height: 18vh;
    }

}

.a{
    display: flex;
    align-items: center;
    align-self: center;
    height: 100%;
    margin-right: 3vw;

    width: 390px;

    @include mobile{
        width: 410px;
        height: 90%;
    }


}

.greyA{
    display: flex;
    align-items: center;
    align-self: center;
    height: 100%;
    margin-right: 3vw;

    width: 390px;

    @include mobile{
        width: 410px;
        height: 90%;
    }

    filter: grayscale(100%);
    opacity: 0.7;
}

.sombra{
    margin-left: 0.5vw;
    width: 100%;
    height: 95%;

    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;    

  
}

.contenedorImagenPublicacion{
    
    display: block;
    height: auto;
    width: 100%;
    border-radius: 40px;
    z-index: -1;
    position: relative;

    
}

.tituloPublicacion{
    color: #112B3C;
    font-weight: 400; 
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    
    //font-size:2vw

    font-size: 32px;

    @include tabletLandSacpe{
        font-size: 24px;
    }

    @include tabletPortrait{
        font-size: 20px;
        font-weight: bold;
    }

    @include mobile{
        font-size: 15px;
        font-weight: bold;
    }
}


.precioPublicacion{
    display: inline;
    color: #112B3C;
    font-weight: 400;
    
    //font-size: 1.8vw;

    font-size: 29px;

    @include tabletLandSacpe{
        font-size: 21px;
    }

    @include tabletPortrait{
        font-size: 20px;
        
    }

    @include mobile{
        font-size: 15px;
    }
}

.porHoraL{
    display: inline;
    color: #BDBDBD;
    font-weight: 400;
    margin-top: 10px;
    margin-left: 5px ;
    
    //font-size: 1vw; 

    font-size: 15px;
    
    @include tabletLandSacpe{
        font-size: 11px;
    }

    @include tabletPortrait{
        font-size: 11px;
        margin-top: 7px;
    }

    @include mobile{
        font-size: 8px;
        margin-top: 7px;
    }
}


.metrosCuadradosPublicacion{
    margin-top: 0.6vh;
    color: #112B3C;
    font-weight: 400;
    
    //font-size: 1vw;  
    
    
    font-size: 15px;

    @include tabletLandSacpe{

    }

    @include tabletPortrait{
        font-size: 15px;

    }

    @include mobile{
        font-size: 10px;
    }
}

.zonaPublicacion{
    color: #112B3C;
    font-weight: 400;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    
    
    //font-size: 1vw;  

    font-size: 18px;

    @include tabletLandSacpe{
        font-size: 14px;
    }

    @include tabletPortrait{
        font-size: 14px;
    }

    @include mobile{
        font-size: 10px;
    }
}



.likeButton2{
    width: 100%;
    height: 100%;

   
}

.likeButton{
    all: unset;

    @include mobile{
        width: 200px;
    }
}

.likeButton3{
    display:none
}

.b{
    all: unset;
    min-width: 70%;
    max-width: 70%;
    
    display: flex;
    flex-direction: column;
    align-self: center;


    @include desktop{
        min-width: 60%;
        max-width: 60%; 
    }
    @include tabletLandSacpe{
        min-width: 50%;
        max-width: 50%; 
    }
    @include tabletPortrait{
        min-width: 50%;
        max-width: 50%; 
    }
    @include mobile{
        max-width: 50%;
        min-width: 50%;
    }
}

.greyB{
    all: unset;
    min-width: 70%;
    max-width: 70%;
    
    display: flex;
    flex-direction: column;
    align-self: center;


    @include desktop{
        min-width: 60%;
        max-width: 60%; 
    }
    @include tabletLandSacpe{
        min-width: 50%;
        max-width: 50%; 
    }
    @include tabletPortrait{
        min-width: 50%;
        max-width: 50%; 
    }
    @include mobile{
        max-width: 50%;
        min-width: 50%;
    }
    filter: grayscale(100%);
    opacity: 0.7;
}

.porHoraListado{
    display: flex;
    flex-direction: row;
    background-color: #112B3C;
}

.imagenPubli{
    background:no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 40px;   

    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.botonesVEE{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 5vw;
}

.tash{
    width: 40px;
    height: 40px;
    color: #DB4437;
}

.eye{
    width: 40px;
    height: 40px;
}

.pen{
    width: 40px;
    height: 40px;
    color: #4285F4;
}