@import "../global.scss";
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

.autocompletado {
    max-width: 50vw;
    margin-top: 5px;
    position: absolute;
    z-index: 9999;
    background-color: white;
    border: 0.5px solid #E5E5E5;
    border-radius: 3px;
    max-height: 300px; /* Ajusta según el espacio disponible o preferencia */
    overflow-y: clip; /* Habilita el scroll interno si el contenido excede la max-height */
    
}

.filaAu{
    all:unset;
    width:39.764vw;
    padding-left: 15px;
    background-color: white;

    display: flex;
    align-items: center;

    
    @include desktop{
        width: 36.5vw;
    }

    @include tabletPortrait{
        width: 54vw;
    }

    @include mobile{
        width: 54vw;
        top:-1vh
    }

}

.filaAu:hover{
    background-color:#f9e4d4
}

.nada{
    all:unset;
}

.textoAu{
    font-family: 'Source Sans Pro','sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #212529;

    margin-bottom:8px;
    margin-top:8px;
    overflow: hidden;  /* Esconde el contenido que excede el tamaño del contenedor */
    white-space: nowrap;  /* Evita que el texto se ajuste en varias líneas */
    text-overflow: ellipsis; 
}