@import "../global.scss";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.fondoPu {
    background-color: #f0f0f0;
    margin-bottom: -3vw;
    overflow-x: hidden;
    padding-bottom: 3vw;
}

.cajaPrincipalPublicar {
    margin-top: 3vw;
    margin-left: 3vw;
    margin-right: 3vw;
    border-radius: 50px;
    background: #ffffff;
    box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
}

.centarPu {
    display: flex;
    justify-content: center;
}

.publicaTuEspacioPu {
    color: #ee6e2d;
    font-size: 35px;
    font-weight: 700;
    margin-top: 1vw;
}


.caja2Pu {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.textoPu {
    margin-top: 1vw;
    margin-left: 6vw;

    @include tabletPortrait {
        margin-top: 50px;
    }
    @include mobile {
        margin-top: 40px;
    }
}

.tituloPu {
    color: #112b3c;
}

.subtituloPu {
    font-family: "Source Sans Pro", sans-serif;
    color: #112b3c;
    font-weight: 300;
    margin-left: 1vw;
    margin-right: 0.5;
    font-size: 30px;
    margin-left: 1vw;

    @include tabletPortrait {
        font-size: 20px;
    }

    @include mobile {
        font-size: 20px;
    }
}

//El boton circular chiquito
.uploadButtonPu {
    border-width: 1px;
    border-color: #bebebe;
    border-style: dashed;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    width: fit-content;
    height: fit-content;
}

.uploadButtonPu2 {
    margin-top: 8px;
    border-width: 1px;
    border-color: #bebebe;
    border-style: dashed;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    width: fit-content;
    height: fit-content;

    width: 20vw;
    height: 20vw;
}

.uploadButtonPu2Error {
    margin-top: 8px;
    border-width: 1px;
    border-color: #db2f2f;
    border-style: dashed;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    width: fit-content;
    height: fit-content;

    width: 20vw;
    height: 20vw;
}

.camaraButtonPu {
    color: #bebebe;
    //con border radius 0 lo hago cuadrado
    border-radius: 0;
}


.diasPu {
    display: flex;
    flex-direction: row;
}

.horasPu {
    //max-width: 10vw;
    max-width: 150px;

    @include tabletPortrait {
        max-width: 100px;
    }
    @include mobile {
        max-width: 90px;
    }
}

.caja1Pu {
    width: 10vw;
    height: 10vw;
}

.diasSelectorPu {
    //margin-top: -0.5vw;
    //margin-bottom: 3.05vw;
    margin-top: -10px;
    margin-bottom: 59px;
}

.horariosPu {
    margin-bottom: 19px;
}

.progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: blue;
    width: 0%;
    transition: width 0.3s;
}

.crearPublicacion {
    border-color: transparent;

    padding: 15px 25px;

    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 3vw;

    margin-top: 3vw;
    margin-bottom: 3vw;

    color: #ffffff;

    background: #ee6e2d;

    border-radius: 50px;

    @include tabletPortrait {
        margin-top: 6vw;
        font-size: 25px;
    }
    @include mobile {
        margin-top: 25vw;
        font-size: 20px;
    }
}

.crearPublicacionDisabled {
    border-color: transparent;

    padding: 15px 25px;

    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 3vw;

    margin-top: 3vw;

    color: #ffffff;

    background: #d0b197;

    border-radius: 50px;

    @include tabletPortrait {
        margin-top: 6vw;
        font-size: 25px;
    }
    @include mobile {
        margin-top: 25vw;
        font-size: 20px;
    }
}

.crearPublicacion:hover {
    border-color: rgba(0, 0, 0, 0.35);
}

.precioPu {
    max-width: 182px;
}

.metrosPu {
    margin-left: 5.7vw;
}

.pPU {
    max-width: 20vw;
}

.lPU {
    max-width: 20vw;
}

.cPU {
    max-width: 15vw;
}

.aPU {
    max-width: 15vw;
}

.plcaPU {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.plcaPU2 {
    display: flex;
    flex-direction: row;
    margin-left: 7vw;
    flex-wrap: wrap;
}

.iPU {
    max-width: 12vw;
    height: 12vw;
    object-fit: cover;

    margin-top: 0.5vw;
    margin-right: 1vw;

    border: 1.5px solid;
    border-color: #bebebe;


    @include tabletLandSacpe {
        max-width: 13vw;
        height: 13vw;
    }

    @include tabletPortrait {
        max-width: 18vw;
        height: 18vw;
    }

    @include mobile {
        max-width: 25vw;
        height: 25vw;
    }
}

.superContainerImagenesPu {
    max-width: 90vw;
}

.crossButtonPu {
    position: relative;
    display: inline-block;
    margin-left: 2%,
}

.deleteButtonPu {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(200, 200, 200, 0.6);
    color: white;
    border: none;
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.deleteButtonPu:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.xButtonPu {
    margin-left: -3.5vw;
    margin-top: 0.5vw;
    border-radius: 50%;
    height: 2.5ch;
    width: 2.5ch;
}

.enLineaPu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.terminosPu {
    color: #ed6c02;
}

.terminosPu:hover {
    color: #cc5c00;
    font-style: bold;
}

.terminosPu2 {
    margin-top: 2vw;
    margin-bottom: -1vw;
}

.error {
    color: #db2f2f;
}

.mmPu {
    padding-bottom: 1vw;
}

.animacionLoaderPu {
    width: 200px;
    height: 200px;
    margin-top: -80px;
    margin-bottom: -80px;
    margin-left: -80px;
    margin-right: -80px;
    border-radius: 100%;
}

.animacionLoaderPu2 {
    width: 40px;
    height: 40px;
}

.crearPublicacion2 {
    border-color: transparent;

    padding: 6px 6px;

    margin-top: 3vw;
    margin-bottom: 3vw;

    color: #ffffff;

    background: #ee6e2d;

    border-radius: 100%;

    @include tabletPortrait {
        margin-top: 6vw;
        font-size: 25px;
    }
    @include mobile {
        margin-top: 25vw;
        font-size: 20px;
    }
}

.errorPu {
    background-color: #ffbfb0;
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;

    margin-left: 1vw;
    margin-bottom: 50px;

    width: 30vw;

    color: #3d3d3d;

    font-family: Arial, sans-serif;
    font-weight: bold;

    border: #de5246 solid 4px;

    @include tabletLandSacpe {
        margin-left: 7vw;
        width: 70%;
    }

    @include tabletPortrait {
        margin-left: 7vw;
        width: 80%;
    }

    @include mobile {
        margin-left: 7vw;
        width: 80%;
    }
}

.botonCopiar {
    margin-left: 10rem;
    margin-bottom: 2vw;

    @include tabletPortrait{
        margin-left: 0rem;
    }
    @include mobile{
        margin-left: 0rem;
    }
}

.radioButtonPu {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 60px;
}

.textoRadioPu {
    margin-top: 10px;
}

.autocomplete-container {
    position: relative;
}

.autoCompleteBox {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;

    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 4px 0 5px;
    position: relative;
    border-radius: 4px;
    padding: 16.5px 14px;
    width: 80vw;
    border: unset;
    border: 1px solid;
    border-color: hsl(0, 0%, 80%);
}

.autoCompleteBox:focus {
    all: unset;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;

    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 4px 0 5px;
    position: relative;
    border-radius: 4px;
    padding: 16.5px 14px;
    width: 80vw;
    border: unset !important;
    border: 1px solid !important;
    border-color: #ed6c02 !important;
    box-shadow: 0 0 0 1px #ed6c02;
}

.autoCompleteBox::-webkit-input-placeholder {
    /* Chrome, Opera, Safari */
    color: #808080;
}

.autoCompleteBox::-moz-placeholder {
    /* Firefox */
    color: #808080;
    opacity: 1;
}

.autoCompleteBox:-ms-input-placeholder {
    /* Internet Explorer */
    color: #808080;
}

.autoCompleteBox::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #808080;
}

.pac-item:hover {
    background-color: #f9e4d4;
}

.autoCompleteBoxError {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;

    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 4px 0 5px;
    position: relative;
    border-radius: 4px;
    padding: 16.5px 14px;
    width: 80vw;
    border: unset;
    border: 1px solid;
    border-color: #d32f2f;
}

.autoCompleteBoxError::-webkit-input-placeholder {
    /* Chrome, Opera, Safari */
    color: #d32f2f;
}

.autoCompleteBoxError:focus {
    all: unset;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;

    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 4px 0 5px;
    position: relative;
    border-radius: 4px;
    padding: 16.5px 14px;
    width: 80vw;
    border: unset !important;
    border: 1px solid !important;
    border-color: #d32f2f !important;
    box-shadow: 0 0 0 1px #d32f2f;
}

.mq-result.mq-cursor:hover {
    background-color: #f9e4d4;
}

.mq-result.mq-cursor {
    background-color: #f9e4d4;
}

.mq-place-search {
    width: 80vw;
}

.mq-input {
    height: 60px;
    border-color: hsl(0, 0%, 80%);
}

span.icon {
    display: none !important;
}

.geoapify-autocomplete-input {
    width: 80vw !important;
    border-color: hsl(0, 0%, 80%) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    height: 60px !important;

    font-family: "Roboto", Helvetica, Arial, sans-serif !important;
    letter-spacing: 0.15008px !important;
    padding: 16.5px 14px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #5c5c5c !important;
}

.css-6j8wv5-Input {
    margin-left: 5px !important;
}

.geoapify-autocomplete-items div:hover {
    background-color: #f9e4d4 !important;
}

.geoapify-autocomplete-input::placeholder {
    color: #8b8b8b;
}

.ubicacionError {
    color: #db2f2f !important;
}

.crearError {
    color: #db2f2f !important;
    font-size: large !important;
    top: 0 !important;
    margin-bottom: 2vw !important;
}
