@import "../global.scss";
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.fondoCuenta{
    background-color: #f0f0f0;
    overflow-x: hidden;
    padding-bottom: 20vh;

    scroll-snap-align: end;

    margin-bottom: 0vh;
}


.cajaPrincipalCuenta{
    margin-top: 3vw;
    margin-left: 3vw;
    margin-right: 3vw;
    border-radius: 50px;
    padding-bottom: 3vw;
    background: #FFFFFF;
    box-shadow:  20px 20px 60px #bebebe,
                -20px -20px 60px #ffffff;
}

.centarCuenta{
    display: flex;
    justify-content: center;
}

.publicaTuEspacioCuenta{
    color:#ee6e2d;
    font-size: 35px;
    font-weight: 700;
    margin-top: 1vw;
}

.textoCuenta{
    margin-top: 1vw;
    margin-left: 6vw;

    
    @include tabletPortrait{
        margin-top: 50px;
    }
    @include mobile{
        margin-top: 40px;
    }
}

.tituloCuenta{
    color: #112B3C; 
}

.subtituloCuenta{
    font-family: 'Source Sans Pro', sans-serif;
    color: #112B3C;
    font-weight: 300;
    margin-right: 0.5;
    font-size: 30px;
    margin-left:1vw;

    @include tabletPortrait{
        font-size: 20px;
    }

    @include mobile{
        font-size: 20px;
    }
}

.caja2Cuenta{
    margin-right: 0.5;
    margin-left:6.6vw;
}

.confirmarBotonCuenta{
    margin-top:20px;
    margin-left:7.4vw;
}

.containerModal{
    width: 100%;
    height: 100%;

}

.contenedorConfigMp{
    display:flex;
    flex-direction: row;

    @include tabletLandSacpe{
        flex-direction: column;
    }

    @include tabletPortrait{
        flex-direction: column;
    }

    @include mobile{
        flex-direction: column;
    }
}

.alertaConfigMp{
    margin:auto;

    background-color: #ffbfb0;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;

    width: 35vw;

    color: #3D3D3D;

    font-family: Arial, sans-serif;
    font-weight: bold;


    border: #de5246 solid 4px;

    @include tabletLandSacpe{
        margin-left:7vw;
        width: 80%;
        margin-top: 10px;

    }

    @include tabletPortrait{
        margin-left:7vw;
        width: 80%;
        margin-top: 10px;
    }

    @include mobile{
        margin-left:7vw;
        width: 80%;
        margin-top: 10px;
    }

}

.confirmarAlertaMp{
    color: #FF2722;
}