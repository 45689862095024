
.contenedorFooter{
  
}
.footer {
  background-color: #EE6E2D;
  color: #FFFF;
  width: 100%;
  position: relative;
  display: flex;
  scroll-snap-align: start;
  scroll-snap-stop:always;
  flex-direction: column;
}


.footer-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  padding-left: 10vw;
  padding-right: 10vw;
}

.footer-section {
  width:15vw
}

.footer-section h3 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}

.footer-section ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-section li {
  margin-bottom: 10px;
}

.footer-section a {
  color: #FFFF;
  text-decoration: none;
}

.footer-copyright {
  text-align: center;
  padding: 20px;
}

.footer-copyright hr {
  border-top: 1px solid #FFFF;
  margin: 10px 0;
}

.footer-copyright p {
  margin: 0;
  font-size: 14px;
  color: #FFFF;
}

.footer-section2 ul {
  list-style: none;
  display: flex;
  align-items: center;
}

.footer-section2 li {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
