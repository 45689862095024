
@mixin desktop {
    @media all and (min-width: 1024px) and (max-width: 1280px) {
        @content
    }
}


@mixin tabletLandSacpe {
    @media all and (min-width: 768px) and (max-width: 1024px) {
        @content
    }
}

@mixin tabletPortrait {
    @media all and (min-width: 480px) and (max-width: 767px) {
        @content
    }
}

@mixin mobile {
    @media all and (max-width: 479px) {
        @content
    }
}

@mixin extraMui {
    @media all and (min-width: 1px) and (max-width: 899px) {
        @content
    }
}




