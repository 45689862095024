@import "../../global.scss";
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');


.bL {
  all: unset;
  min-width: 70%;
  max-width: 70%;

  display: flex;
  flex-direction: column;
  align-self: center;

  margin-left:-2vw;

  @include desktop {
    min-width: 60%;
    max-width: 60%;
  }
  @include tabletLandSacpe {
    min-width: 50%;
    max-width: 50%;
  }
  @include tabletPortrait {
    min-width: 50%;
    max-width: 50%;
  }
  @include mobile {
    max-width: 50%;
    min-width: 50%;
  }
}
